import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'


interface Props {
  data: IndexQueryQuery
  location: Location
}

const OurTeamPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const product1 = data.product1?.childImageSharp?.fluid
  const jestine_img = data.jestine_img?.childImageSharp?.fluid
  const karen_img = data.karen_img?.childImageSharp?.fluid
  const katie_img = data.katie_img?.childImageSharp?.fluid
  const merrissa_img = data.merrissa_img?.childImageSharp?.fluid
  const sara_img = data.sara_img?.childImageSharp?.fluid
  const coming_soon_img = data.coming_soon_img?.childImageSharp?.fluid
  const emily_img = data.emily_img?.childImageSharp?.fluid
  const amanda_img = data.amanda_img?.childImageSharp?.fluid
  const cristina_img = data.cristina_img?.childImageSharp?.fluid
  const pigeon_img = data.pigeon_img?.childImageSharp?.fluid
  const emma_img = data.emma_img?.childImageSharp?.fluid
  const gina_img = data.gina_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Our Team  - The Dentists At Gateway Crossing</title>
      <meta name="description" content="Book your appointment We’re here to treat you like family in our brand new state of the art facility." />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Our Team - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="Book your appointment We’re here to treat you like family in our brand new state of the art facility." />
      <meta property="og:url" content="https://thedentistsatgc.com/about/our-team/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-01-08T17:15:29+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Our Team - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="Book your appointment We’re here to treat you like family in our brand new state of the art facility." />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg team-hero-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
        alt="The Dentists at Gateway Crossing"
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <ContainerFluid>
            <div className="col-lg-6 text-center team-hero-left">
              <p className="hero-text">
                Want to know who we are?
              </p>
              <h1 className="text-light our-team-head text-center">
                Our Team
              </h1>
              <a href="#about-section-1"><button className="btn-rounded btn-team-1"> Learn More </button></a>
              <a href="https://flexbook.me/tdagc/website"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
            <div className="col-lg-6 text-center team-hero-right">
              <Img fluid={hero_img} className="team-hero-img" alt="the dentist at gateway crossing team"/>
            </div>
          </ContainerFluid>
        </div>
      </BackgroundImage>

      <section id="about-section-1" className="page-section text-center">
          <Container>
            <div className="col-lg-12">
             <Video
                videoSrcURL="https://drive.google.com/file/d/1aBJWp3QNINRNa6dNWPWzTa_EVrB_AofU/preview"
                videoTitle="Our Team"
              />
            </div>
          </Container>
          <Container>
            <div className="col-lg-12"><p>"We’re here to treat you like family in our brand new state of the art facility."</p></div>
          </Container>
        </section>
      <section id ="about-section-2" className="page-section">
        <Container>
          <div className="col-lg-12 text-center mb-4">
            <h2>Our Team</h2>
          </div>
          <div className="col-lg-4">
            <div className="team-container text-center">
              <Img fluid={jestine_img} className="teams-img"/>
              <h4>Jestine</h4>
              <p> Registered Dental Hygienist</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="team-container text-center">
              <Img fluid={sara_img} className="teams-img"/>
              <h4>Sara</h4>
              <p>Registered Dental Hygienist</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="team-container text-center">
              <Img fluid={emma_img} className="teams-img"/>
              <h4>Emma</h4>
              <p>Registered Dental Hygienist</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="team-container text-center">
              <Img fluid={merrissa_img} className="teams-img"/>
              <h4>Merrissa</h4>
              <p>Expanded Functions Dental Assistant</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="team-container text-center">
              <Img fluid={katie_img} className="teams-img"/>
              <h4>Katie</h4>
              <p>Expanded Functions Dental Assistant</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="team-container text-center">
              <Img fluid={emily_img} className="teams-img"/>
              <h4>Emily</h4>
              <p>Expanded Functions Dental Assistant</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="team-container text-center">
              <Img fluid={karen_img} className="teams-img"/>
              <h4>Karen</h4>
              <p>Patient Care Coordinator</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="team-container text-center">
              <Img fluid={gina_img} className="teams-img"/>
              <h4>Gina</h4>
              <p>Patient Care Coordinator</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="team-container text-center">
              <Img fluid={pigeon_img} className="teams-img"/>
              <h4>Pigeon</h4>
              <p>Chief Morale Officer</p>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default OurTeamPage

export const query = graphql`
  query OurTeamPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    product1: file(relativePath: { eq: "images/marco-pharma.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "team-img-compressed_resized" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    jestine_img: file(name: { eq: "team-jestine" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    abigail_img: file(name: { eq: "team-abigail" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    gina_img: file(name: { eq: "team-gina" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    emma_img: file(name: { eq: "team-emma" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    karen_img: file(name: { eq: "team-karen" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    katie_img: file(name: { eq: "team-katie" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    merrissa_img: file(name: { eq: "team-merrissa" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    sara_img: file(name: { eq: "Sara" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    coming_soon_img: file(name: { eq: "coming-soon" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    emily_img: file(name: { eq: "Emily" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    amanda_img: file(name: { eq: "Amanda" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    cristina_img: file(name: { eq: "team-cristina" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pigeon_img: file(name: { eq: "team-pigeon" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
